export function handleDates(date, separator) {
    if (typeof date === 'object') {
        const originalDate = new Date(date);
        const dayDate = originalDate.getUTCDate();
        const monthDate = originalDate.getUTCMonth() + 1;
        const yearDate = originalDate.getUTCFullYear();
        const finalDate = `${yearDate}${separator}${monthDate
            .toString()
            .padStart(2, '0')}${separator}${dayDate
            .toString()
            .padStart(2, '0')}`;
        return finalDate;
    }
    const [year, month, day] = date.split(separator);
    const result = [day, month, year].join('/');
    return result;
}
