import Axios from 'axios';
import * as actionType from './actionTypes';

export const initReconciliationList = (filter) => {
    return (dispatch) => {
        dispatch({ type: actionType.RECONCILIATION_GET_REQUEST });
        Axios.get(`/reconciliation${filter}`)
            .then((response) => {
                dispatch({
                    type: actionType.RECONCILIATION_GET_SUCCESS,
                    reconciliationData: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionType.RECONCILIATION_GET_FAILURE,
                    error,
                });
            });
    };
};

export const initReconciliationCount = (filter) => {
    return (dispatch) => {
        dispatch({ type: actionType.RECONCILIATION_COUNT_GET_REQUEST });
        Axios.get(`/reconciliation/count${filter}`)
            .then((response) => {
                dispatch({
                    type: actionType.RECONCILIATION_COUNT_GET_SUCCESS,
                    reconciliationCount: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionType.RECONCILIATION_COUNT_GET_FAILURE,
                    error,
                });
            });
    };
};

export const onInitPaymentReceivedCount = (filter) => {
    return (dispatch) => {
        dispatch({ type: actionType.PAYMENTRECEIVED_COUNT_GET_REQUEST });
        Axios.get(`/reconciliation/paymentsCount${filter}`)
            .then((response) => {
                dispatch({
                    type: actionType.PAYMENTRECEIVED_COUNT_GET_SUCCESS,
                    paymentReceivedCount: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionType.PAYMENTRECEIVED_COUNT_GET_FAILURE,
                    error,
                });
            });
    };
};

export const initPaymentReceivedList = (filter) => {
    return (dispatch) => {
        dispatch({ type: actionType.PAYMENTRECEIVED_GET_REQUEST });
        Axios.get(`/reconciliation/getreceivedpayments${filter}`)
            .then((response) => {
                dispatch({
                    type: actionType.PAYMENTRECEIVED_GET_SUCCESS,
                    paymentReceivedData: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionType.PAYMENTRECEIVED_GET_FAILURE,
                    error,
                });
            });
    };
};

export const onInitReconciliationAuditList = (filter) => {
    return (dispatch) => {
        dispatch({ type: actionType.RECONCILIATION_AUDIT_GET_REQUEST });
        Axios.get(`/reconciliation/getreconciliationaudits${filter}`)
            .then((response) => {
                dispatch({
                    type: actionType.RECONCILIATION_AUDIT_GET_SUCCESS,
                    reconciliationAuditData: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionType.RECONCILIATION_AUDIT_GET_FAILURE,
                    error,
                });
            });
    };
};

export const onInitReconciliationAuditCount = (filter) => {
    return (dispatch) => {
        dispatch({ type: actionType.RECONCILIATION_AUDIT_COUNT_GET_REQUEST });
        Axios.get(`/reconciliation/countreconciliationaudits${filter}`)
            .then((response) => {
                dispatch({
                    type: actionType.RECONCILIATION_AUDIT_COUNT_GET_SUCCESS,
                    reconciliationAuditCount: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionType.RECONCILIATION_AUDIT_COUNT_GET_FAILURE,
                    error,
                });
            });
    };
};
