import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from 'reactstrap';

import Header from '../../components/Header/Header';
import EvtFilter from '../../components/EvtFilter/EvtFilter';
import EvtTable from '../../components/EvtTable/EvtTable';
import messages from './ReconciliationListPage.intl';
import reconciliationAuditMessages from './ReconciliationAudit/ReconciliationAuditListPage.intl';
import Tabs from '../../components/Tabs/Tabs';
import * as actions from '../../store/actions';
import { filterPropertyTypes } from '../../utils/enums';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { DateTimeParser } from '../../utils/dateTimeParser';

class ReconciliationListPage extends Component {
    state = {
        activeTab: 0,
        filters: [],
        filtersPayment: [],
        filtersReconciliationAudit: [],
    };

    componentDidMount() {
        // É colocado um filtro inicial para pegar a data inicial desse mês e do mês anterior
        this.setState({
            filters: this.getInitialFilter(),
            filtersPayment: this.getInitialFilter(),
            filtersReconciliationAudit: this.getInitialFilter(),
        });
    }

    resetFilters = () => {
        this.setState({
            filters: this.getInitialFilter(),
            filtersPayment: this.getInitialFilter(),
            filtersReconciliationAudit: this.getInitialFilter(),
        });
    };

    getInitialFilter = () => {
        return [
            {
                property: 'payDate',
                operator: 'ge',
                value: this.handleDate('initial'),
            },
            {
                property: 'payDate',
                operator: 'le',
                value: this.handleDate('end'),
            },
        ];
    };

    reloadReconciliationList = () => {
        const { query } = this.state;

        this.props.onInitReconciliationList(query);
        this.props.onInitReconciliationCount(query);
    };

    reloadPaymentReceivedList = () => {
        const { query } = this.state;

        this.props.onInitPaymentReceivedList(query);
        this.props.onInitPaymentReceivedCount(query);
    };

    reloadReconciliationAuditList = () => {
        const { query } = this.state;

        this.props.onInitReconciliationAuditList(query);
        this.props.onInitReconciliationAuditCount(query);
    };

    handleFiltersChange = (filters) => {
        this.setState({
            filters,
        });
    };

    handleFiltersPaymentReceivedChange = (filtersPayment) => {
        this.setState({
            filtersPayment,
        });
    };

    handleFiltersReconciliationAuditChange = (filtersReconciliationAudit) => {
        this.setState({
            filtersReconciliationAudit,
        });
    };

    handleDate = (key) => {
        const date = new Date();
        switch (key) {
            case 'initial':
                return new Date(date.getFullYear(), date.getMonth(), 1)
                    .toISOString()
                    .slice(0, -14);

            case 'end':
                return new Date(date.getFullYear(), date.getMonth() + 1, 0)
                    .toISOString()
                    .slice(0, -14);
            default:
                return new Date().toISOString();
        }
    };

    handleTabClick = (e, index) => {
        if (this.state.activeTab !== index) {
            this.setState({
                activeTab: index,
            });
            this.resetFilters();
        }
    };

    handleRefreshButtonClick = () => this.reloadReconciliationList();

    handleEvTableStateChange = (query) => {
        this.setState({ query }, () => this.reloadReconciliationList());
    };

    handleEvtPaymentReceivedTableStateChange = (query) => {
        this.setState({ query }, () => this.reloadPaymentReceivedList());
    };

    handleEvtReconciliationAuditTableStateChange = (query) => {
        this.setState({ query }, () => this.reloadReconciliationAuditList());
    };

    render() {
        const {
            intl,
            loadingList,
            reconciliationData,
            reconciliationCount,
            paymentReceivedCount,
            paymentReceivedData,
            loadingPaymentReceivedList,
            reconciliationAuditCount,
            reconciliationAuditData,
            loadingReconciliationAuditList,
        } = this.props;

        const {
            filters,
            filtersPayment,
            filtersReconciliationAudit,
        } = this.state;

        const properties = [
            {
                key: 'operatorTransactionId',
                value: intl.formatMessage(messages.operatorTransactionId),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'paymentDate',
                value: intl.formatMessage(messages.paymentDate),
                type: filterPropertyTypes.DATE,
            },
            {
                key: 'reconciliationTypeDescription',
                value: intl.formatMessage(
                    messages.reconciliationTypeDescription,
                ),
                type: filterPropertyTypes.NUMBER,
            },
            {
                key: 'paymentValue',
                value: intl.formatMessage(messages.paymentValue),
                type: filterPropertyTypes.NUMBER,
                map: (input) => input.replace(/\./g, '').replace(/,/g, '.'),
            },
            {
                key: 'taxValue',
                value: intl.formatMessage(messages.taxValue),
                type: filterPropertyTypes.NUMBER,
                map: (input) => input.replace(/\./g, '').replace(/,/g, '.'),
            },
            {
                key: 'bankAgency',
                value: intl.formatMessage(messages.bankAgency),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'bankAccount',
                value: intl.formatMessage(messages.bankAccount),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'bankCode',
                value: intl.formatMessage(messages.bankCode),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'transactionRefId',
                value: intl.formatMessage(messages.transactionRefId),
                type: filterPropertyTypes.TEXT,
            },
        ];

        const columns = [
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages.operatorTransactionId)}
                        </span>
                    </div>
                ),
                accessor: 'operatorTransactionId',
                minWidth: 160,
                sortable: false,
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.paymentDate)}</span>
                    </div>
                ),
                accessor: 'paymentDate',
                maxWidth: 140,
                Cell: (row) => {
                    if (row.value) {
                        return DateTimeParser(row.value);
                    }
                    return '00/00/0000 : 00:00';
                },
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(
                                messages.reconciliationTypeDescription,
                            )}
                        </span>
                    </div>
                ),
                accessor: 'reconciliationTypeDescription',
                maxWidth: 110,
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages.installmentBeginEnd)}
                        </span>
                    </div>
                ),
                Cell: (row) => {
                    const {
                        installmentBegin = 0,
                        installmentEnd = 0,
                    } = row.original;
                    return `${installmentBegin}/${installmentEnd}`;
                },
                sortable: false,
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.paymentValue)}</span>
                    </div>
                ),
                accessor: 'paymentValue',
                maxWidth: 90,
                Cell: (props) =>
                    props.value > 0 &&
                    new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    }).format(props.value),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.taxValue)}</span>
                    </div>
                ),
                accessor: 'taxValue',
                maxWidth: 90,
                Cell: (props) =>
                    new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    }).format(props.value),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.bankDetails)}</span>
                    </div>
                ),
                Cell: (row) => {
                    const { bankCode, bankAccount, bankAgency } = row.original;
                    return `(${bankCode}) ${bankAccount}/${bankAgency}`;
                },
                sortable: false,
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages.transactionRefId)}
                        </span>
                    </div>
                ),
                Cell: (row) => {
                    const { transactionRefId } = row.original;
                    return transactionRefId !== undefined ? (
                        <a
                            target='_blank'
                            href={`/transactions/${transactionRefId}`}
                            rel='noreferrer'
                        >
                            {transactionRefId}
                        </a>
                    ) : (
                        'Não encontrado'
                    );
                },
                minWidth: 160,
                sortable: false,
            },
        ];

        const paymentReceivedProperties = [
            {
                key: 'operatorTransactionId',
                value: intl.formatMessage(
                    messages.operatorPaymentReceivedTransactionId,
                ),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'payDate',
                value: intl.formatMessage(messages.payDate),
                type: filterPropertyTypes.DATE,
            },
            {
                key: 'collectionId',
                value: intl.formatMessage(messages.collectionId),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'paymentValue',
                value: intl.formatMessage(messages.paymentPaymentValue),
                type: filterPropertyTypes.NUMBER,
            },
            {
                key: 'paymentMethodType',
                value: intl.formatMessage(messages.paymentMethodType),
                type: filterPropertyTypes.TEXT,
            },
        ];

        const paymentReceivedColumns = [
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages.operatorTransactionId)}
                        </span>
                    </div>
                ),
                accessor: 'operatorTransactionId',
                minWidth: 200,
                sortable: false,
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.collectionId)}</span>
                    </div>
                ),
                accessor: 'collectionId',
                minWidth: 200,
                sortable: false,
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.payDate)}</span>
                    </div>
                ),
                maxWidth: 180,
                Cell: (row) => {
                    if (row.original?.payDate) {
                        return `${new Date(
                            row.original.payDate,
                        ).toLocaleDateString(navigator.language)}`;
                    }
                    return '00/00/0000, 00:00';
                },
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages.paymentPaymentValue)}
                        </span>
                    </div>
                ),
                accessor: 'paymentValue',
                minWidth: 160,
                Cell: (row) =>
                    row.value > 0
                        ? new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                          }).format(row.value)
                        : 'R$ 0,00',
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages.paymentMethodType)}
                        </span>
                    </div>
                ),
                accessor: 'paymentMethodType',
                minWidth: 160,
            },
        ];

        const reconciliationAuditProperties = [
            {
                key: 'operatorTransactionId',
                value: intl.formatMessage(
                    messages.operatorPaymentReceivedTransactionId,
                ),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'payDate',
                value: intl.formatMessage(messages.payDate),
                type: filterPropertyTypes.DATE,
            },
            {
                key: 'collectionId',
                value: intl.formatMessage(messages.collectionId),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'paymentValue',
                value: intl.formatMessage(messages.paymentPaymentValue),
                type: filterPropertyTypes.NUMBER,
            },
            {
                key: 'paymentMethodType',
                value: intl.formatMessage(messages.paymentMethodType),
                type: filterPropertyTypes.TEXT,
            },
        ];

        const reconciliationAuditColumns = [
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages.operatorTransactionId)}
                        </span>
                    </div>
                ),
                accessor: 'operatorTransactionId',
                minWidth: 200,
                sortable: false,
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.collectionId)}</span>
                    </div>
                ),
                accessor: 'collectionId',
                minWidth: 200,
                sortable: false,
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.payDate)}</span>
                    </div>
                ),
                maxWidth: 180,
                Cell: (row) => {
                    if (row.original?.payDate) {
                        return `${new Date(
                            row.original.payDate,
                        ).toLocaleDateString(navigator.language)}`;
                    }
                    return '00/00/0000, 00:00';
                },
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages.paymentPaymentValue)}
                        </span>
                    </div>
                ),
                accessor: 'paymentValue',
                minWidth: 160,
                Cell: (row) =>
                    row.value > 0
                        ? new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                          }).format(row.value)
                        : 'R$ 0,00',
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages.paymentMethodType)}
                        </span>
                    </div>
                ),
                accessor: 'paymentMethodType',
                minWidth: 160,
            },
        ];

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/transactions',
                            name: intl.formatMessage(messages.title),
                            active: true,
                        },
                    ]}
                />
                <Tabs
                    handleTabClick={this.handleTabClick}
                    activeTab={this.state.activeTab}
                    tabs={[
                        {
                            name:
                                messages.reconciliationTabTitle.defaultMessage,
                            component: (
                                <>
                                    <header className='table-screen'>
                                        <section className='title'>
                                            <Header
                                                title={intl.formatMessage(
                                                    messages.title,
                                                )}
                                                subtitle={`${reconciliationCount} ${intl.formatMessage(
                                                    messages.subtitle,
                                                )}`}
                                            />
                                        </section>
                                        <section className='btns-topo'>
                                            <EvtFilter
                                                properties={properties}
                                                handleFiltersChange={
                                                    this.handleFiltersChange
                                                }
                                                loading={loadingList}
                                            />
                                            <button
                                                className={`new-btn small ${
                                                    this.props.loadingList
                                                        ? 'loading'
                                                        : ''
                                                }`}
                                                onClick={
                                                    this
                                                        .handleRefreshButtonClick
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon='sync'
                                                    size='1x'
                                                />
                                            </button>
                                        </section>
                                    </header>
                                    <section className='content-middle'>
                                        <EvtTable
                                            filters={filters}
                                            columns={columns}
                                            data={reconciliationData}
                                            length={reconciliationCount}
                                            pageSize={10}
                                            handleStateChange={
                                                this.handleEvTableStateChange
                                            }
                                            loading={loadingList}
                                            defaultSorted={[
                                                {
                                                    id: 'paymentDate',
                                                    desc: true,
                                                },
                                            ]}
                                        />
                                    </section>
                                </>
                            ),
                        },
                        {
                            name: messages.paymentTabTitle.defaultMessage,
                            component: (
                                <>
                                    <header className='table-screen'>
                                        <section className='title'>
                                            <Header
                                                title={intl.formatMessage(
                                                    messages.paymentTitle,
                                                )}
                                                subtitle={`${paymentReceivedCount} ${intl.formatMessage(
                                                    messages.paymentSubtitle,
                                                )}`}
                                            />
                                        </section>
                                        <section className='btns-topo'>
                                            <EvtFilter
                                                properties={
                                                    paymentReceivedProperties
                                                }
                                                handleFiltersChange={
                                                    this
                                                        .handleFiltersPaymentReceivedChange
                                                }
                                                loading={
                                                    loadingPaymentReceivedList
                                                }
                                                // Como foi o primeiro filtro de tela no componentDidMount, vamos inidicá-lo no forms do filtro
                                                filters={[
                                                    {
                                                        id: 'firstFilter',
                                                        operator: '',
                                                        property: 'payDate',
                                                        showAddButton: true,
                                                        type: 3,
                                                        value: '',
                                                        valueFrom: this.handleDate(
                                                            'initial',
                                                        ),
                                                        valueTo: this.handleDate(
                                                            'end',
                                                        ),
                                                    },
                                                ]}
                                            />
                                            <button
                                                className={`new-btn small ${
                                                    this.props.loadingList
                                                        ? 'loading'
                                                        : ''
                                                }`}
                                                onClick={
                                                    this
                                                        .handleRefreshButtonClick
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon='sync'
                                                    size='1x'
                                                />
                                            </button>
                                        </section>
                                    </header>
                                    <section className='content-middle'>
                                        <EvtTable
                                            filters={filtersPayment}
                                            columns={paymentReceivedColumns}
                                            data={paymentReceivedData}
                                            length={paymentReceivedCount}
                                            pageSize={10}
                                            handleStateChange={
                                                this
                                                    .handleEvtPaymentReceivedTableStateChange
                                            }
                                            loading={loadingPaymentReceivedList}
                                            defaultSorted={[
                                                {
                                                    id: 'payDate',
                                                    desc: true,
                                                },
                                            ]}
                                        />
                                    </section>
                                </>
                            ),
                        },
                        {
                            name:
                                messages.reconciliationAuditTabTitle
                                    .defaultMessage,
                            component: (
                                <>
                                    <header className='table-screen'>
                                        <section className='title'>
                                            <Header
                                                title={intl.formatMessage(
                                                    reconciliationAuditMessages.title,
                                                )}
                                                subtitle={`${reconciliationAuditCount} ${intl.formatMessage(
                                                    reconciliationAuditMessages.subtitle,
                                                )}`}
                                            />
                                        </section>
                                        <section className='btns-topo'>
                                            <EvtFilter
                                                properties={
                                                    reconciliationAuditProperties
                                                }
                                                handleFiltersChange={
                                                    this
                                                        .handleFiltersReconciliationAuditChange
                                                }
                                                loading={
                                                    loadingReconciliationAuditList
                                                }
                                                filters={[
                                                    {
                                                        id: 'firstFilter',
                                                        operator: '',
                                                        property: 'payDate',
                                                        showAddButton: true,
                                                        type: 3,
                                                        value: '',
                                                        valueFrom: this.handleDate(
                                                            'initial',
                                                        ),
                                                        valueTo: this.handleDate(
                                                            'end',
                                                        ),
                                                    },
                                                ]}
                                            />
                                            <button
                                                className={`new-btn small ${
                                                    this.props.loadingList
                                                        ? 'loading'
                                                        : ''
                                                }`}
                                                onClick={
                                                    this
                                                        .handleRefreshButtonClick
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon='sync'
                                                    size='1x'
                                                />
                                            </button>
                                        </section>
                                    </header>
                                    <section className='content-middle'>
                                        <EvtTable
                                            filters={filtersReconciliationAudit}
                                            columns={reconciliationAuditColumns}
                                            data={reconciliationAuditData}
                                            length={reconciliationAuditCount}
                                            pageSize={10}
                                            handleStateChange={
                                                this
                                                    .handleEvtReconciliationAuditTableStateChange
                                            }
                                            loading={
                                                loadingReconciliationAuditList
                                            }
                                            defaultSorted={[
                                                {
                                                    id: 'payDate',
                                                    desc: true,
                                                },
                                            ]}
                                        />
                                    </section>
                                </>
                            ),
                        },
                    ]}
                />
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        reconciliationData: state.reconciliationReducer.reconciliationData,
        reconciliationCount: state.reconciliationReducer.reconciliationCount,
        loadingList: state.reconciliationReducer.loadingList,

        loadingPaymentReceivedList:
            state.reconciliationReducer.loadingPaymentReceivedList,
        paymentReceivedData: state.reconciliationReducer.paymentReceivedData,
        paymentReceivedCount: state.reconciliationReducer.paymentReceivedCount,

        loadingReconciliationAuditList:
            state.reconciliationReducer.loadingReconciliationAuditList,
        reconciliationAuditData:
            state.reconciliationReducer.reconciliationAuditData,
        reconciliationAuditCount:
            state.reconciliationReducer.reconciliationAuditCount,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onInitReconciliationList: (filter) =>
            dispatch(actions.initReconciliationList(filter)),
        onInitReconciliationCount: (filter) =>
            dispatch(actions.initReconciliationCount(filter)),
        onInitPaymentReceivedList: (query) =>
            dispatch(actions.initPaymentReceivedList(query)),
        onInitPaymentReceivedCount: (query) =>
            dispatch(actions.onInitPaymentReceivedCount(query)),
        onInitReconciliationAuditList: (query) =>
            dispatch(actions.onInitReconciliationAuditList(query)),
        onInitReconciliationAuditCount: (query) =>
            dispatch(actions.onInitReconciliationAuditCount(query)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ReconciliationListPage),
);
