import React, { Component } from 'react';

import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Badge from 'reactstrap/lib/Badge';
import messages from './FinancialDocumentsListPage.intl';
import * as actions from '../../store/actions/index';
import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import EvtFilter from '../../components/EvtFilter/EvtFilter';
import EvtTable, {
    calculateColumnWidth,
} from '../../components/EvtTable/EvtTable';
import { filterPropertyTypes } from '../../utils/enums';
import { AuthContext } from '../../context/AuthContext';
import { ReactComponent as NFIcon } from '../../assets/icons/svgIcons/NFIcon.svg';
import { ReactComponent as PaymentIcon } from '../../assets/icons/svgIcons/PaymentIcon.svg';
import TooltipItem from '../../components/Tooltip/Tooltip';
import { queryParser } from '../../utils/queryParser';
import { handleCpfCnpj } from '../../utils/handleCpfCnpj';

class FinancialDocumentsListPage extends Component {
    static contextType = AuthContext;

    state = {
        filters: [],
        query: '',
    };

    reloadFinancialDocumentsList = () => {
        const { query } = this.state;
        if (!this.props.redirect) {
            this.props.onInitFinancialDocuments(query);
            this.props.onInitFinancialDocumentsCount(query);
        } else {
            this.props.onInitCard(false);
        }
    };

    handleRowClick = (e, id) => {
        this.props.history.push(`/financialDocuments/${id}`);
    };

    handleGetTrProps = (state, rowInfo) => {
        return {
            style: {
                cursor: 'pointer',
            },
            onClick: (e) => {
                const { nodeName } = e.target;
                if (
                    nodeName === 'BUTTON' ||
                    nodeName === 'svg' ||
                    nodeName === 'path'
                ) {
                    return;
                }

                this.props.history.push(
                    `/financialDocuments/${rowInfo.original.id}`,
                );
            },
        };
    };

    handleRefreshButtonClick = () => {
        this.reloadFinancialDocumentsList();
    };

    handleFiltersChange = (filters) => {
        this.setState({
            filters,
        });
    };

    handleEvTableStateChange = (query) => {
        this.setState(
            {
                query: queryParser(query, this.props.query),
            },
            () => {
                this.reloadFinancialDocumentsList();
            },
        );
    };

    handleDownloadClick = (fileId) => {
        const url = `${window.API_URI}/files/${fileId}`;
        window.open(url, 'Download');
    };

    render() {
        const {
            intl,
            loadingList,
            financialDocuments,
            financialDocumentsCount,
        } = this.props;
        const { filters } = this.state;
        const properties = [
            {
                key: 'referenceDocument',
                value: intl.formatMessage(messages.referenceDocument),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'documentDate',
                value: intl.formatMessage(messages.documentDate),
                type: filterPropertyTypes.DATE,
            },
            {
                key: 'dueDate',
                value: intl.formatMessage(messages.dueDate),
                type: filterPropertyTypes.DATE,
            },
            {
                key: 'documentValue',
                value: intl.formatMessage(messages.documentValue),
                type: filterPropertyTypes.NUMBER,
            },
            {
                key: 'statusCode',
                value: intl.formatMessage(messages.status),
                type: filterPropertyTypes.ENUM,
                enums: [
                    { key: 1, value: 'A vencer' },
                    { key: 2, value: 'Pago' },
                    { key: 4, value: 'Vencido' },
                ],
            },
        ];

        const columns = [
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages.referenceDocument)}
                        </span>
                    </div>
                ),
                accessor: 'referenceDocument',
                minWidth:
                    20 +
                    calculateColumnWidth(
                        financialDocuments,
                        'referenceDocument',
                        intl.formatMessage(messages.referenceDocument),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages.clientCpfCnpj)}
                        </span>
                    </div>
                ),
                accessor: 'clientCpfCnpj',
                Cell: (row) => {
                    const documentNumber = handleCpfCnpj(row.value);
                    return documentNumber;
                },
                minWidth: 150,
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.documentDate)}</span>
                    </div>
                ),
                accessor: 'documentDate',
                Cell: (row) => {
                    const date = new Date(row.value).toLocaleDateString(
                        navigator.language,
                    );
                    return date;
                },
                minWidth: 150,
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.dueDate)}</span>
                    </div>
                ),
                accessor: 'dueDate',
                Cell: (row) => {
                    const date = new Date(row.value).toLocaleDateString(
                        navigator.language,
                    );
                    return date;
                },
                minWidth: 150,
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages.documentValue)}
                        </span>
                    </div>
                ),
                accessor: 'documentValue',
                Cell: (props) =>
                    new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    }).format(props.value),

                sortable: false,
                minWidth: 160,
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages.statusDescription)}
                        </span>
                    </div>
                ),
                accessor: 'statusCode',
                Cell: (row) => {
                    let status;
                    let color;
                    switch (row?.value) {
                        case 2:
                            status = 'Pago';
                            color = 'success';
                            break;
                        case 4:
                            status = 'Vencido';
                            color = 'danger';
                            break;
                        default:
                            status = 'A vencer';
                            color = 'warning';
                            break;
                    }

                    return (
                        <Badge
                            style={{ fontSize: '0.8rem', color: '#ffffff' }}
                            color={color}
                            pill
                        >
                            {status}
                        </Badge>
                    );
                },
                minWidth: 95,
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.files)}</span>
                    </div>
                ),
                accessor: 'files',
                style: {},
                Cell: (row) => {
                    const files = row.original?.files;
                    const fileNf = files?.find(
                        (f) => f?.fileName?.toLowerCase() === 'notafiscal',
                    );
                    const fileBoleto = files?.find(
                        (f) => f?.fileName?.toLowerCase() === 'boleto',
                    );
                    const fileIdNf = fileNf?.fileId;
                    const fileIdBoleto = fileBoleto?.fileId;
                    return (
                        <div style={{ display: 'flex' }}>
                            {fileIdNf && (
                                <div
                                    onClick={() => {
                                        this.handleDownloadClick(fileIdNf);
                                    }}
                                    style={{ width: 30, height: 30 }}
                                    id='Tooltip-notafiscal'
                                    className='financialIcon m-8'
                                >
                                    <NFIcon />
                                </div>
                            )}
                            {fileIdBoleto && (
                                <div
                                    onClick={() => {
                                        this.handleDownloadClick(fileIdBoleto);
                                    }}
                                    style={{ width: 30, height: 30 }}
                                    id='Tooltip-boleto'
                                    className='financialIcon m-8'
                                >
                                    <PaymentIcon />
                                </div>
                            )}
                            {fileNf && (
                                <TooltipItem
                                    id='notafiscal'
                                    message={fileNf?.fileName}
                                />
                            )}
                            {fileBoleto && (
                                <TooltipItem
                                    id='boleto'
                                    message={fileBoleto?.fileName}
                                />
                            )}
                        </div>
                    );
                },
                minWidth: 100,
            },
            {
                /* Header: () => <div className='header-table-title' />, */
                accessor: 'payment',
                Cell: (row) => {
                    const paymentLink = row.original?.paymentLink;
                    return (
                        <div style={{ display: 'flex' }}>
                            {paymentLink && (
                                <button
                                    className='btn table'
                                    type='button'
                                    onClick={() => {
                                        window.open(paymentLink, 'Download');
                                    }}
                                >
                                    {intl.formatMessage(messages.pay)}
                                </button>
                            )}
                        </div>
                    );
                },
                minWidth: 100,
            },
        ];

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/financialdocuments',
                            name: intl.formatMessage(messages.title),
                            active: true,
                        },
                    ]}
                />
                <header className='table-screen'>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(messages.title)}
                            subtitle={
                                financialDocumentsCount +
                                intl.formatMessage(messages.subtitle)
                            }
                        />
                    </section>
                    <section className='btns-topo'>
                        <EvtFilter
                            properties={properties}
                            handleFiltersChange={this.handleFiltersChange}
                            loading={loadingList}
                        />
                        <button
                            className={`new-btn small ${
                                this.props.loadingList ? 'loading' : ''
                            }`}
                            onClick={this.handleRefreshButtonClick}
                        >
                            <FontAwesomeIcon icon='sync' size='1x' />
                        </button>
                    </section>
                </header>
                <section className='content-middle'>
                    <EvtTable
                        filters={filters}
                        columns={columns}
                        data={financialDocuments}
                        length={financialDocumentsCount}
                        pageSize={10}
                        handleStateChange={this.handleEvTableStateChange}
                        handleGetTrProps={this.handleGetTrProps}
                        loading={loadingList}
                        defaultSorted={[
                            {
                                id: 'isPayed',
                                desc: false,
                            },
                        ]}
                    />
                </section>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        financialDocuments:
            state.financialDocumentsReducer.financialDocuments || [],
        loadingList: state.financialDocumentsReducer.loadingList,
        redirect: state.financialDocumentsReducer.redirect,
        financialDocumentsCount:
            state.financialDocumentsReducer.financialDocumentsCount,
        query: state.financialDocumentsReducer.query,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitFinancialDocuments: (filter) =>
            dispatch(actions.initFinancialDocuments(filter)),
        onInitCard: (bool) => {
            dispatch(actions.setCard(bool));
        },
        onInitFinancialDocumentsCount: (filter) =>
            dispatch(actions.initFinancialDocumentsCount(filter)),
        onInitCardQuery: (query) => dispatch(actions.setCardQuery(query)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(FinancialDocumentsListPage),
);
